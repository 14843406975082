
    




  if(Cookies.get('kook')) {
    $(".cookien-banner").css('display','none');
  }else {
    $(".cookien-banner").css('display','block');
  }


$(".kendu-btn").click(function(){
  $(".cookien-banner").css('display','none');
  Cookies.set('kook','bai',{expires: 1});

});

$(".scroll-behera").click(function(){
    
    
     $("html, body").animate({scrollTop: $('#title1').offset().top }, 1000);
    $(".scroll-behera").css('opacity','0');
    });
    



// Main Corousel
(function($) {

var $item = $('.carousel-item');
var $wHeight = $(window).height()-50;

$item.height($wHeight); 
$item.addClass('full-screen');

var $numberofSlides = $('.carousel-item').length;
var $currentSlide = Math.floor((Math.random() * $numberofSlides));

$('.carousel-indicators li').each(function(){
  var $slideValue = $(this).attr('data-slide-to');
  if($currentSlide == $slideValue) {
    $(this).addClass('active');
    $item.eq($slideValue).addClass('active');
  } else {
    $(this).removeClass('active');
    $item.eq($slideValue).removeClass('active');
  }
});

$('.carousel img').each(function() {
  var $src = $(this).attr('src');
  var $color = $(this).attr('data-color');
  $(this).parent().css({
    'background-image' : 'url(' + $src + ')',
    'background-color' : $color
  });
  $(this).remove();
});

$(window).on('resize', function (){
  $wHeight = $(window).height();
  $item.height($wHeight);
});

$('.carousel').carousel({
  interval: 6000,
  pause: "false"
});


})(jQuery);



var $animation_elements = $('.animation-element');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);
 
  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);
 
    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
      $element.addClass('in-view');
    } else {
      $element.removeClass('in-view');
    }
  });
    
    if (window_top_position>600){
         $(".scroll-behera").css('opacity','0');
    }else {
         $(".scroll-behera").css('opacity','1');
    }
    
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

